/**
 * Created by Rama on 8/9/17.
 */
import * as $ from 'jquery';
import * as Cookies from 'js-cookie';
import * as _ from 'underscore';
import * as Backbone from 'backbone';
import * as moment from 'moment';

var Handlebars = require('handlebars/runtime');

var _sync = Backbone.sync;
Backbone.sync = function (method, model, options) {
    // Add trailing slash to backbone model views
    var parts = _.result(model, 'url').split('?'),
        _url = parts[0],
        params = parts[1];

    _url += _url.charAt(_url.length - 1) === '/' ? '' : '/';

    if (!_.isUndefined(params)) {
        _url += '?' + params;
    }

    options = _.extend(options, {
        url: _url,
    });

    if (!options.beforeSend) {
        options.beforeSend = function (xhr) {
            xhr.setRequestHeader('X-CSRFToken', Cookies.get('csrftoken'));
        }
    }

    return _sync(method, model, options);
};

Handlebars.registerHelper('prettyDate', function(rawDate) {
    return rawDate ? moment(rawDate).format('MM/DD/YYYY') : '';
});

Handlebars.registerHelper('prettyDateTime', function(rawDate) {
    return rawDate ? moment(rawDate).format('MM/DD/YYYY hh:mm:ss a') : '';
});

Handlebars.registerHelper('wordify', function(value) {
    return value
        ? value.replace(/_/, ' ').toLowerCase().split(' ').map(function(word) {
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ')
        : '';
});

Handlebars.registerHelper('ifIn', function(elem, list, options) {
    if(list.indexOf(elem) > -1) {
        return options.fn(this);
    }
    return options.inverse(this);
});

Handlebars.registerHelper('toLowerCase', function(str) {
    return str.toLowerCase();
});

Handlebars.registerHelper('getPrintValueOrDefault', function(value, printValue) {
    if (!printValue) {
        printValue = value;
    }
    return value ? printValue : 'Not set';
});

Handlebars.registerHelper('getValueOrDefault', function(value) {
    return value ? value : 'Not set';
});

Handlebars.registerHelper('toCurrency', function(value) {
    return '$' + value;
});

Handlebars.registerHelper('hasValue', function(value) {
    return value ? 'Yes' : 'No';
});

Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {

    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '!=':
            return (v1 != v2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
});

Handlebars.registerHelper('pluralize', function(term, count) {
    return count > 1 ? term + 's' : term;
});

var objHasOwnProperty = function(obj, prop) {
    var proto = obj.__proto__ || obj.constructor.prototype;
    return (prop in obj) && (!(prop in proto) || proto[prop] !== obj[prop]);
};

var getParameterByName = function(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

var updateUrl = function(url, queryString, useBackboneHistory) {
    if (!url && url !== '') url = window.location.pathname;
    useBackboneHistory = typeof(useBackboneHistory) === 'boolean' ? useBackboneHistory : true;
    var lastChar = url.substr(-1);
    if (lastChar !== '/') {
        url += '/';
    }
    if (!queryString && queryString !== '') {
        url += window.location.search;
    } else {
        url += queryString;
    }
    if (window.location.href.replace(window.origin, '') !== url) {
        if (useBackboneHistory) {
            Backbone.history.navigate(url);
        } else {
            window.history.pushState(null, '', url);
        }
    }
};

var updateQueryStringParameter = function(key, value, uri) {
    uri = typeof(uri) === 'string' ? uri : window.location.search;
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (value === null) {
        uri = uri.replace(re, '');
        if (uri && !uri.startsWith('?')) {
            uri = '?' + uri;
        }
        return uri;
    }
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    }
    else {
        return uri + separator + key + '=' + value;
    }
};

var parseQueryString = function(camelize) {
    var queryString = window.location.search.slice(1);

    var params = {};
    var keys = queryString.split('&').filter(function (x) {
        return x !== '';
    });

    $.each(keys, function (i, equalString) {
        var keyVal = equalString.split('=');
        params[keyVal[0]] = decodeURIComponent(keyVal[1]);
    });

    return params;
};

function hideAndShowNext($obj) {
    $obj.hide()
        .next()
        .removeClass('hide-mobile')
        .addClass('show-mobile');
}

export {
    objHasOwnProperty, getParameterByName, updateUrl, updateQueryStringParameter, parseQueryString, hideAndShowNext
};
